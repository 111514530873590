<template>
  <div>
    <v-row class="mb-5">
      <h1 class="outline--text">Article parser queue</h1>
      <v-spacer></v-spacer>
      <span class="pt-3 px-5">{{ articles.timestamp }}</span>
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        @click="fetchArticleData"
        >Refresh</v-btn
      >
    </v-row>
    <v-simple-table :loading="isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Total</th>
            <th class="text-left">New</th>
            <th class="text-left">Processing</th>
            <th class="text-left">Completed</th>
            <th class="text-left">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ articles.total }}</td>
            <td style="background-color: #b0bed9; font-weight: 600">
              {{ articles.new }}
            </td>
            <td
              style="background-color: lightgoldenrodyellow; font-weight: 600"
            >
              {{ articles.processing }}
            </td>
            <td style="background-color: lightgreen; font-weight: 600">
              {{ articles.completed }}
            </td>
            <td style="background-color: #e47365; font-weight: 600">
              {{ articles.error }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { GET_DASHBOARD } from "@/store/modules/dashboard/actions";
import { mapGetters } from "vuex";

export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      currentUser: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["articlesData"]),
    articles: function () {
      if (this.isLoading) {
        return [];
      }
      return this.articlesData;
    },
  },
  methods: {
    fetchArticleData: function () {
      console.log('fetchArticleData')
      const vm = this;
      vm.currentUser = this.$store.getters["user/currentUser"];
      vm.$store.dispatch(`dashboard/${GET_DASHBOARD}`, vm.id);
      vm.isLoading = false;
    },
  },
  mounted: function () {
    this.fetchArticleData();
  },
};
</script>
